import React from 'react';
import Link from 'gatsby-link';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import Layout from "../layouts";

class BlogIndex extends React.Component {
	render() {
		const siteTitle = this.props.data.site.siteMetadata.title;
		const posts = this.props.data.allMarkdownRemark.edges;

		return (
			<Layout>
				<Helmet title={siteTitle}/>
				<div className="container">
					<section className="jumbotron">
						<div className="row">
							<div className="col-md-12">
								<h1>Our blog</h1>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<Link
									className={"btn btn-first"}
									to="/pricing">
									API pricing &amp; docs
								</Link>
							</div>
						</div>

						<div className="row">
							{posts.map(({node}) => {
								const title = get(node, 'frontmatter.title') || node.fields.slug;

								return <div key={node.fields.slug} className="col-xs-12 col-sm-12">
									<Link style={{boxShadow: 'none'}} className="card" to={node.fields.slug}>
										<div className="card-content">
											<h4 className="card-title">{title}</h4>
											<p>{node.frontmatter.excerpt}</p>
											<aside className="dates">
												{node.frontmatter.created_at}
											</aside>
										</div>
									</Link>
								</div>
							})}
						</div>
					</section>
				</div>
			</Layout>
		)
	}
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___created_at], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            created_at(formatString: "DD MMMM, YYYY")
            title
            excerpt
          }
        }
      }
    }
  }
`
